import ThirdParty, { Google } from "supertokens-auth-react/recipe/thirdparty";
import Session from "supertokens-auth-react/recipe/session";
console.log(`ENV DEV: ${process.env.DEV}`);
let appInfo = process.env.DEV === "Y" ? ({
    appName: "Devsoft Hello (Local)",
    apiDomain: "http://localhost:8080",
    websiteDomain: "http://localhost:3000",
}) : ({
    appName: "Devsoft Hello",
    apiDomain: "https://identity.songtek.net",
    websiteDomain: "https://hello2.songtek.net",
});
export const SuperTokensConfig = {
    appInfo: appInfo,
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        ThirdParty.init({
            override: {
                components: {
                    ThirdPartySignInAndUpHeader_Override: ({ DefaultComponent, ...props }) => {
                        return (
                            <div>
                                <img src="/logo.png" alt="Song Technologies" />
                                <DefaultComponent {...props} />
                            </div>
                        );
                    },
                }
                // functions: (originalImplementation) => {
                //     return {
                //         ...originalImplementation,
                //         // signInAndUp: async (input: { userContext: any; options?: RecipeFunctionOptions }) => {
                //         //     debugger;
                //         //     try {
                //         //         let error = await originalImplementation.signInAndUp(input);
                //         //         return error;
                //         //     } catch (error) {
                //         //         return "hello";
                //         //     }
                //         // },

                //         // we will only be overriding what happens when a user
                //         // clicks the sign in button.
                //         getAuthErrorFromURL: async function (input) {
                //             let error = originalImplementation.getAuthErrorFromURL(input);
                //             setTimeout(() => {
                //                 document.querySelector('#supertokens-root').shadowRoot.querySelector('div[data-supertokens="generalError"]').innerText = "Unable to login. Devsoft employees must login with Microsoft accounts. Other users, please login with Google workspace accounts";
                //             }, 500);
                //             return error;
                //         },
                //         // ...
                //         // TODO: override more functions
                //     }
                // }
            },
            signInAndUpFeature: {
                providers: [
                    {
                        id: "active-directory",
                        name: "Microsoft/Devsoft Accounts", // Will display "Continue with X"
                    },
                    Google.init()
                ],
            },
            // getRedirectionURL: async (context) => {
            //     console.log(context);
            //     debugger;
            //     if (context.action === "SUCCESS") {
            //         if (context.redirectToPath !== undefined) {
            //             // we are navigating back to where the user was before they authenticated
            //             return context.redirectToPath;
            //         }
            //         return "/dashboard";
            //     }
            //     return undefined;
            // }
        }),
        Session.init({
            // sessionScope: ".devsoft.vn",
            sessionScope: appInfo.websiteDomain.includes("localhost") ? undefined : '.songtek.net',
        }),
    ],
};
