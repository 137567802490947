import React, { useEffect, useState } from 'react'
import { Datagrid, DateField, EmailField, List, NumberField, TextField, ReferenceArrayField, SingleFieldList, ChipField, EditGuesser, ShowGuesser, ListGuesser } from 'react-admin';
import VisitorIcon from '@mui/icons-material/People';
import { RoleList } from "./RoleList"
import { RoleEdit } from "./RoleEdit"
export default {
    list: RoleList,
    edit: RoleEdit,
    show: ShowGuesser,
    // create: RoleEdit,
    // icon: VisitorIcon,
};
