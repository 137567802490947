import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';
import RoleReferenceInput from './RoleReferenceInput';

export const UserEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="email" />
            <TextInput label="Full Name" source="medadata.full_name" />
            <TextInput label="First Name" source="medadata.first_name" />
            <TextInput label="Last Name" source="medadata.last_name" />
            <RoleReferenceInput
                reference="roles"
                source="roles"
                label="Roles"
            />
        </SimpleForm>
    </Edit>
);