import React, { useEffect, useState } from 'react'
import { Datagrid, DateField, EmailField, List, NumberField, TextField, ReferenceArrayField, SingleFieldList, ChipField, EditGuesser, ShowGuesser } from 'react-admin';
import { UserEdit } from "./edit"
import VisitorIcon from '@mui/icons-material/People';
const roleSort = { field: 'name', order: 'ASC' };
const UserList: React.FC = () => (
    <List perPage={50}>
        <Datagrid rowClick="edit">
            <TextField label="First Name" source="medadata.first_name" />
            <TextField label="Last Name" source="medadata.last_name" />
            <EmailField source="email" />
            <ReferenceArrayField
                label="Roles"
                reference="roles"
                source="roles"
                sortBy="roles.name"
                sort={roleSort}
            >
                <SingleFieldList>
                    {/* https://stackblitz.com/edit/react-admin-custom-chipfield-color?file=posts.js */}
                    <ChipField source="name" size="small" />
                </SingleFieldList>
            </ReferenceArrayField>
            <DateField source="user.timeJoined" />
            <TextField source="user.thirdParty.id" />
        </Datagrid>
    </List>
);

export default {
    list: UserList,
    edit: UserEdit,
    show: ShowGuesser,
    // icon: VisitorIcon,
};
