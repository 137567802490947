
const authProvider = {
    login: async ({ otp }) => {
        return Promise.resolve({ redirectTo: '/' });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkAuth: () => {
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject({ redirectTo: '/auth' });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'John Doe',
        }),
    getPermissions: () => Promise.resolve(''),
    getJWT: () => {
        const tokenJson = localStorage.getItem('token');
        if (!tokenJson) {
            throw new Error("Not logged in");
        }
        const token = JSON.parse(tokenJson);
        return token.jwt;
    },
};

export default authProvider;