import { Edit, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import FeaturesInput from './FeaturesInput';
export const RoleEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput label="Name" source="id" required={true} />
            {/* <FeaturesInput label="Features" source="permissions" /> */}
            <ReferenceArrayInput
                label="Features"
                source="permissions"
                reference='features'>
                <SelectArrayInput
                    optionText="id"
                    optionValue="permissions" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);