import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from "axios";
import * as Session from "supertokens-auth-react/recipe/session";
async function refreshSession() {
    await Session.attemptRefreshingSession();
    return await axios.get(process.env.DEV === "Y" ? "http://localhost:8080/jwt/otp" : "https://identity.songtek.net/jwt/otp");
}
export const Goto: React.FC = () => {
    const [otp, setOtp] = useState(null);
    useEffect(() => {
        // Session.
        // axios            .get(process.env.DEV === "Y" ? "http://localhost:8080/jwt/otp" : "https://identity.songtek.net/jwt/otp")
        refreshSession().then(response => setOtp(response.data.otp))
            .catch(err => console.error(err));
    }, []); // load once
    if (!otp)
        return <div>Loading ...</div>;

    const { url } = useParams(), actualUrl = atob(url);
    console.log(`Goto URL ${actualUrl}`);
    setTimeout(async () => {
        let uri = new URL(actualUrl);
        uri.searchParams.append('otp', otp);
        window.location.href = uri.toString();
    }, 0);

    return (
        <div className="main">
            You are logged in successfully.
            Click <a href={actualUrl}>here</a> if your browser is not redirected.
        </div>
    );
}
