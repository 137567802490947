import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Admin, Resource, ListGuesser } from 'react-admin';
import { dataProvider } from './DataProvider';
import authProvider from './authProvider';
import users from './User';
import roles from './Role';


export const AdminPage: React.FC = () => (
    <Admin basename="/admin" dataProvider={dataProvider} authProvider={authProvider} requireAuth={true}>
        <Resource name="users" {...users} />
        <Resource name="roles" {...roles} />
    </Admin>
);