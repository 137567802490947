import React, { useEffect, useState } from 'react'
import { TopToolbar, FilterButton, CreateButton, Datagrid, DateField, EmailField, List, NumberField, TextField, ReferenceArrayField, SingleFieldList, ChipField, EditGuesser, ShowGuesser, ListGuesser } from 'react-admin';
// import FeaturesField from './FeaturesField';
const roleSort = { field: 'name', order: 'ASC' };
const RoleListActions = () => (
    <TopToolbar>
        {/* <FilterButton /> */}
        <CreateButton />
    </TopToolbar>
);

export const RoleList: React.FC = () => (
    <List actions={<RoleListActions />}>
        <Datagrid rowClick="edit" sort={roleSort}>
            <TextField source="name" />
            {/* <FeaturesField source="permissions" /> */}
        </Datagrid>
    </List >
);
